export default class EncryptionTypes {
  constructor() {
    this.items = [
      {
        id: 1,
        type: "CFBAES",
        label:"CFBAES",
      },
      {
        id: 2,
        type: "GCMAES",
        label:"GCMAES",
      },
      {
        id: 3,
        type: "RSA",
        label:"RSA",
      },
      {
        id: 4,
        type: "TWOFISH",
        label:"TWOFISH",
      },
    ];
  }
}